import { useCallback, useState } from 'react'
import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Link,
} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import NavigateNext from '@mui/icons-material/NavigateNext'
import { introStep } from 'types'
import { Modal } from 'components'
import { useCompleteStep } from 'hooks'
import { NotificationBadge } from './NotificationBadge'
import { useGetIntroStepStatusesQueryState } from 'Api'

interface IntroListItemProps {
  step: introStep
  isNextIncompleteStep: boolean
}

const IntroListItem = ({ step, isNextIncompleteStep }: IntroListItemProps) => {
  const { data } = useGetIntroStepStatusesQueryState()
  const isCompleted = data?.interactions.some((i) => i.id === step.identifier)
  const [open, setOpen] = useState(false)
  const { heading, description, type, identifier } = step
  const shouldCompleteOnClick =
    step.type === 'link' && step.shouldCompleteOnClick

  const completeStep = useCompleteStep(identifier)

  const shouldCompleteStepFn = () => {
    if (shouldCompleteOnClick) completeStep()
  }
  const toggleModalOpen = useCallback(() => {
    setOpen(!open)
  }, [open])

  return (
    <>
      <ListItemButton
        key={identifier}
        data-testid="introListButton"
        sx={{
          pr: 1,
          justifyContent: 'center',
          width: '100%',
          borderRadius: 1,
        }}
        data-gtm-label={identifier}
        LinkComponent={Link}
        {...(type === 'link' && {
          onClick: shouldCompleteStepFn,
          href: step.url,
        })}
        {...(type === 'modal' && {
          onClick: toggleModalOpen,
          component: 'button',
        })}
      >
        <ListItemAvatar>
          {isNextIncompleteStep && <NotificationBadge />}
          <Avatar
            data-testid={isCompleted ? 'completedStep' : 'incompleteStep'}
            sx={{
              bgcolor: isCompleted ? 'secondary.main' : 'surface.grey',
            }}
          >
            <CheckIcon
              sx={{
                color: isCompleted ? 'white' : 'text.disabled',
              }}
            />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{
            component: 'span',
            variant: 'subtitle1',
          }}
          primary={heading}
          secondary={description}
          secondaryTypographyProps={{
            variant: 'body2',
            color: 'text.primary',
            component: 'span',
          }}
        />

        <NavigateNext />
      </ListItemButton>
      {type === 'modal' && (
        <Modal
          open={open}
          toggleModalOpen={toggleModalOpen}
          modalSteps={step.modalSteps}
          isCompletedIntroStep={isCompleted}
          introStepIdentifier={step.identifier}
        />
      )}
    </>
  )
}

export default IntroListItem
