import { PropsWithChildren, useEffect } from 'react'
import { useWizard } from '@trr/wizard-library'
import { useCompleteStep } from 'hooks'

interface StepCompletionHandlerProps extends PropsWithChildren {
  isCompletedIntroStep: boolean | undefined
  introStepIdentifier: string
}

const StepCompletionHandler = ({
  children,
  introStepIdentifier,
}: StepCompletionHandlerProps) => {
  const completeStep = useCompleteStep(introStepIdentifier)
  const wizard = useWizard()

  useEffect(() => {
    if (wizard.isLastStep) {
      completeStep()
    }
  }, [completeStep, wizard.isLastStep])

  return children
}

export default StepCompletionHandler
