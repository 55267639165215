import { Provider } from 'react-redux'
import {
  type AppShellDataProps,
  AppShellDataProvider,
} from '@trr/app-shell-data'
import { defaultLocale, themeOptions } from '@trr/mui-theme'
import { createTheme, ThemeProvider } from '@mui/material'
import App from 'App'
import { store } from './Store'
import { content } from './types'
import { GTMTracker } from '@trr/gtm-tracking'
import { FetchAnsokan } from './features/FetchAnsokan'
import { FetchInteractions } from './features/FetchInteractions'

const Index = ({
  appShellData,
}: {
  appShellData: AppShellDataProps<content>
}) => {
  const theme = createTheme(themeOptions, defaultLocale)
  return (
    <ThemeProvider theme={theme}>
      <AppShellDataProvider value={appShellData}>
        <Provider store={store}>
          <GTMTracker mfName="introwidget">
            <FetchAnsokan>
              <FetchInteractions>
                <App />
              </FetchInteractions>
            </FetchAnsokan>
          </GTMTracker>
        </Provider>
      </AppShellDataProvider>
    </ThemeProvider>
  )
}

export default Index
