import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getAppConfig, getIdToken } from '@trr/app-shell-data'

const {
  COMMON: { PUBLIC_WEB_API_URL, KLIENT_API_URL },
} = getAppConfig()

const apis = {
  PUBLIC_WEB_API_URL: PUBLIC_WEB_API_URL,
  KLIENT_API_URL: KLIENT_API_URL,
}

const extendedBaseQuery = (api: keyof typeof apis) =>
  fetchBaseQuery({
    baseUrl: apis[api],
    prepareHeaders: (headers) => {
      headers.set('authorization', `Bearer ${getIdToken() ?? ''}`)
      headers.set('Content-Type', 'application/json')
      headers.set('Accept-Language', 'sv')
      return headers
    },
  })

export default extendedBaseQuery
