import { Dialog } from '@mui/material'
import { useRef } from 'react'
import { ModalStep } from 'types'
import { ModalContent } from './ModalContent'
import { ModalStepCompletionHandler } from './ModalStepCompletionHandler'
import { Wizard } from '@trr/wizard-library'
import { useTrackCustomClick, useTrackVirtualView } from '@trr/gtm-tracking'
import { TrackStep } from './TrackStep'

const Modal = ({
  open,
  toggleModalOpen,
  modalSteps,
  isCompletedIntroStep,
  introStepIdentifier,
}: {
  open: boolean
  toggleModalOpen: () => void
  modalSteps: ModalStep[]
  isCompletedIntroStep: boolean | undefined
  introStepIdentifier: string
}) => {
  const stepIdentifierRef = useRef<string | null>(null)
  const customEventTracking = useTrackCustomClick()
  const trackStepTrigger = useTrackVirtualView('introWidget')

  const handleClose = () => {
    if (stepIdentifierRef.current) {
      customEventTracking(`button`, {
        label: `${stepIdentifierRef.current} Stäng modal`,
      })
    }
    toggleModalOpen()
  }

  return (
    <Dialog
      open={open}
      sx={{ zIndex: 1999999 }}
      PaperProps={{
        sx: {
          justifyContent: 'space-between',
          height: { xs: '100%', md: '90%' },
          maxHeight: { xs: '100%', md: '800px' },
          width: { xs: '100%', md: '90%' },
          maxWidth: { xs: '100%', md: '1200px' },
          m: 0,
          borderRadius: { xs: 0, md: 1 },
        },
      }}
      onClose={handleClose}
    >
      <Wizard>
        {modalSteps.map((modalStep) => {
          return (
            <ModalStepCompletionHandler
              key={modalStep.identifier}
              isCompletedIntroStep={isCompletedIntroStep}
              introStepIdentifier={introStepIdentifier}
            >
              <TrackStep
                modalStep={modalStep}
                trackStepTrigger={trackStepTrigger}
              >
                <ModalContent
                  step={modalStep}
                  toggleModalOpen={toggleModalOpen}
                  stepIdentifierRef={stepIdentifierRef}
                />
              </TrackStep>
            </ModalStepCompletionHandler>
          )
        })}
      </Wizard>
    </Dialog>
  )
}

export default Modal
