/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { MutableRefObject, useEffect } from 'react'
import { HTMLMapper } from '@trr/html-mapper'
import { useUser } from '@trr/app-shell-data'
import { useWizard } from '@trr/wizard-library'
import {
  Avatar,
  Box,
  DialogContent,
  Fade,
  IconButton,
  MobileStepper,
  Typography,
  useTheme,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { ModalStep } from 'types'
import {
  translateMuiColor,
  replaceVariablesInText,
  buildImageUrl,
  getConfig,
} from 'utils'
import { FontIcon } from 'components'
import { useTrackCustomClick } from '@trr/gtm-tracking'

const ModalContent = ({
  step: {
    identifier,
    icon,
    iconColor,
    subHeading,
    heading,
    contentLeft,
    contentRight,
    imageUrl,
  },
  toggleModalOpen,
  stepIdentifierRef,
}: {
  step: ModalStep
  toggleModalOpen: () => void
  stepIdentifierRef: MutableRefObject<string | null>
}) => {
  const {
    typography: { body1 },
  } = useTheme()
  const customEventTracking = useTrackCustomClick()
  const wizard = useWizard()
  const user = useUser()

  stepIdentifierRef.current = identifier

  const handleCloseButtonClick = () => {
    toggleModalOpen()
    customEventTracking(`button`, { label: `${identifier} Stäng modal` })
  }

  const handleNextStepButtonClick = () => {
    wizard.nextStep()
    customEventTracking(`button`, { label: `${identifier} Gå till nästa steg` })
  }

  const handlePreviouseStepButtonClick = () => {
    wizard.previousStep()
    customEventTracking(`button`, {
      label: `${identifier} Gå till föregående steg`,
    })
  }
  // solution to override epi's template cta button link that react-router messes up. cant navigate to same page
  useEffect(() => {
    const possibleCtaButton = document.querySelector(
      '.introWidgetModalContent [data-testid=ctaButton]'
    )
    if (possibleCtaButton) {
      possibleCtaButton.addEventListener('click', () => {
        toggleModalOpen()
      })
    }
    return () => {
      if (possibleCtaButton) {
        possibleCtaButton.removeEventListener('click', () => {
          toggleModalOpen()
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Box
        sx={{
          overflowX: 'hidden',
          flexGrow: '1',
        }}
      >
        <IconButton
          data-testid="closeModalButton"
          onClick={handleCloseButtonClick}
          sx={{
            position: 'absolute',
            top: { xs: 12, md: 24 },
            right: { xs: 20, md: 32 },
          }}
          aria-label="Stäng modal"
        >
          <CloseIcon />
        </IconButton>
        <Fade in>
          <Box
            data-testid="modalContent"
            display={'flex'}
            flexDirection={'column'}
            width={'100%'}
            height={'100%'}
            sx={{
              pt: { xs: 5, md: 6 },
              pr: { xs: 2, md: 6 },
              pb: { xs: 0, md: 0 },
              pl: { xs: 4, md: 6 },
              backgroundImage: { md: `url(${buildImageUrl(imageUrl)})` },
              backgroundRepeat: { md: 'no-repeat' },
              backgroundPosition: { md: `100% 0px` },
            }}
          >
            <Box display="flex" gap={2}>
              <Avatar
                sx={{
                  height: 48,
                  width: 48,
                  bgcolor: translateMuiColor(iconColor),
                }}
              >
                <FontIcon iconName={icon} color={iconColor} />
              </Avatar>
              <Box>
                <Typography
                  variant="subtitle1"
                  component={'h1'}
                  color={'text.secondary'}
                >
                  {subHeading}
                </Typography>
                <Typography variant="h6" component="h2">
                  {heading}
                </Typography>
              </Box>
            </Box>
            <DialogContent
              className="introWidgetModalContent"
              sx={{
                p: 0,
                m: { xs: '40px 0px 0px 0px', md: '0px 64px 0px 64px' },
                display: 'flex',
                flexDirection: 'column',
                '& p': {
                  lineHeight: { xs: `${body1.lineHeight} !important` },
                  fontSize: { xs: `${body1.fontSize} !important` },
                },
                '& p:last-of-type, & ul:last-of-type': {
                  marginBottom: '0 !important',
                },
                '& [data-testid=ctaButton]': {
                  mt: '32px',
                },
                '& img': {
                  display: { xs: 'none', md: 'unset' },
                },
              }}
            >
              <Box
                display="flex"
                flex={1}
                gap={{ xs: 2, md: 7 }}
                justifyContent={{ xs: 'start', md: 'space-between' }}
                flexDirection={{ xs: 'column', md: 'row' }}
                alignItems={'center'}
              >
                <Box flex={1}>
                  <HTMLMapper
                    body={replaceVariablesInText(contentLeft, user)}
                    mediaUrl={getConfig().MEDIA_URL}
                  />
                </Box>
                <Box flex={1}>
                  <HTMLMapper
                    body={replaceVariablesInText(contentRight, user)}
                    mediaUrl={getConfig().MEDIA_URL}
                  />
                </Box>
              </Box>
            </DialogContent>
          </Box>
        </Fade>
      </Box>
      <Box
        pt={{ xs: 2, md: 4 }}
        pb={{ xs: 4, md: 7 }}
        px={{ xs: 3, md: 6 }}
        display={'flex'}
        justifyContent="center"
      >
        <MobileStepper
          variant="dots"
          steps={wizard.stepCount}
          position="static"
          activeStep={wizard.activeStep}
          sx={{
            maxWidth: 400,
            flexGrow: 1,
            '& .MuiMobileStepper-dot': {
              margin: '0 8px',
            },
          }}
          nextButton={
            <IconButton
              disabled={wizard.isLastStep}
              onClick={handleNextStepButtonClick}
              sx={{ backgroundColor: 'surface.purple' }}
              aria-label="Nästa steg"
              data-testid="nextStepButton"
            >
              <ArrowForwardIcon />
            </IconButton>
          }
          backButton={
            <IconButton
              disabled={wizard.isFirstStep}
              onClick={handlePreviouseStepButtonClick}
              sx={{ backgroundColor: 'surface.purple' }}
              aria-label="Föregående steg"
              data-testid="previousStepButton"
            >
              <ArrowBackIcon />
            </IconButton>
          }
        />
      </Box>
    </>
  )
}

export default ModalContent
