import { PropsWithChildren, useEffect } from 'react'
import { ModalStep } from 'types'
import { useTrackVirtualView } from '@trr/gtm-tracking'

interface TrackStepProps extends PropsWithChildren {
  modalStep: ModalStep
  trackStepTrigger: ReturnType<typeof useTrackVirtualView>
}

const TrackStep = ({
  children,
  modalStep,
  trackStepTrigger,
}: TrackStepProps) => {
  useEffect(() => {
    trackStepTrigger(modalStep.identifier)
  }, [trackStepTrigger, modalStep.identifier])
  return children
}

export default TrackStep
