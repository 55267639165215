import { PropsWithChildren } from 'react'
import { useGetIntroStepStatusesQuery } from 'Api'

const FetchInteractions = ({ children }: PropsWithChildren) => {
  const { isLoading, error } = useGetIntroStepStatusesQuery()
  if (isLoading || error) {
    return null
  }

  return children
}

export default FetchInteractions
