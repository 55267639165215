import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getIdToken } from '@trr/app-shell-data'
import { getConfig } from '../getConfig'

const { API_URL, KLIENT_API_URL } = getConfig()

const apis = {
  API_URL: API_URL,
  KLIENT_API_URL: KLIENT_API_URL,
}

const extendedBaseQuery = (api: keyof typeof apis) =>
  fetchBaseQuery({
    baseUrl: apis[api],
    prepareHeaders: (headers) => {
      headers.set('authorization', `Bearer ${getIdToken() ?? ''}`)
      headers.set('Content-Type', 'application/json')
      headers.set('Accept-Language', 'sv')
      return headers
    },
  })

export default extendedBaseQuery
