import { MuiColors } from 'types'

const colorMap: { [key in MuiColors]: string } = {
  primary: 'primary.light',
  secondary: 'secondary.light',
  success: 'success.light',
  accent: 'accent.light',
  error: 'error.light',
  warning: 'warning.light',
  info: 'info.light',
  neutral: 'neutral.light',
}

export const translateMuiColor = (color: MuiColors): string => {
  return colorMap[color] || 'grey'
}
