import { Box } from '@mui/material'

const NotificationBadge = () => {
  return (
    <Box
      className={'notificationBadge'}
      sx={{
        position: 'relative',
        span: {
          position: 'absolute',
          margin: '0 auto',
          width: '12px',
          height: '12px',
          top: '-8px',
          left: 'calc(100% - 24px)',
          background: '#6222c3',
          borderRadius: '50%',
          animation: 'ball-animate 1s 5',
          '@keyframes ball-animate': {
            '0%': {
              width: '12px',
              height: '12px',
            },
            '20%': {
              width: '10px',
              height: '14px',
            },
            '40%': {
              width: '12px',
              height: '12px',
            },
            '60%': {
              width: '12px',
              height: '12px',
              transform: 'translateY(12px)',
            },
            '80%': {
              width: '14px',
              height: '10px',
            },
            '100%': {
              width: '12px',
              height: '12px',
            },
          },
        },
      }}
    >
      <Box component="span" data-testid="notificationBadge" />
    </Box>
  )
}

export default NotificationBadge
