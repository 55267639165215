import { List } from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'
import { IntroListItem } from './IntroListItem'
import { content } from 'types'
import { useGetIntroStepStatusesQueryState } from 'Api'

const IntroList = () => {
  const { introSteps } = useEpiContent<content>()
  const { data } = useGetIntroStepStatusesQueryState()
  const introStepsIds = introSteps.map((i) => i.identifier)
  const incompleteIntroStepIds = introStepsIds.filter(
    (i) => !data?.interactions.find((s) => s.id === i)
  )
  const nextIncompleteStep = incompleteIntroStepIds[0]
  return (
    <List>
      {introSteps.map((step) => (
        <IntroListItem
          key={step.identifier}
          step={step}
          isNextIncompleteStep={nextIncompleteStep === step.identifier}
        />
      ))}
    </List>
  )
}

export default IntroList
