import { createApi } from '@reduxjs/toolkit/query/react'
import { extendedBaseQuery } from 'utils'

export interface UserInteractionsResponse {
  userId: string
  interactions: {
    id: string
    createdTime: string
    deletedTime: string | null
  }[]
}

interface UserInteractionsRequest {
  interactions: {
    id: string
  }[]
}

export const introStepsApi = createApi({
  reducerPath: 'introStepsApi',
  tagTypes: ['introStepStatuses'],
  baseQuery: extendedBaseQuery('API_URL'),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    getIntroStepStatuses: builder.query<UserInteractionsResponse, void>({
      providesTags: ['introStepStatuses'],
      query: () => `/user-interactions`,
    }),
    postIntroStepStatus: builder.mutation<
      UserInteractionsResponse,
      UserInteractionsRequest
    >({
      query: (request) => {
        return {
          url: '/user-interactions',
          method: 'POST',
          body: request,
        }
      },
    }),
  }),
})

export const {
  useGetIntroStepStatusesQuery,
  usePostIntroStepStatusMutation,
  endpoints: {
    getIntroStepStatuses: { useQueryState: useGetIntroStepStatusesQueryState },
  },
} = introStepsApi

export default introStepsApi
