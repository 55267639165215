import { useRef } from 'react'

declare global {
  interface Window {
    hj: (type: string, data: string) => void
  }
}

const useHotjar = (eventName: string) => {
  const hasTriggeredHotjarEvent = useRef<boolean>(false)
  const sendHotjarEvent = (eventName: string) => {
    if (typeof window.hj === 'function') {
      window.hj('event', eventName)
    }
  }

  const triggerHotjarEvent = () => {
    sendHotjarEvent(eventName)
    hasTriggeredHotjarEvent.current = true
  }

  return {
    triggerHotjarEvent,
    hasTriggeredHotjarEvent: hasTriggeredHotjarEvent.current,
  }
}

export default useHotjar
